<template>
    <div>
        <span v-if="flag && flag.flag">
            <span v-if="flag.flag === 'cms' && operation">comission for </span>
            <span v-if="flag.flag === 'vm'">var.margin for {{flag.flag_instrument}} <span v-if="flag.flag_instrument_pt !== 'long'">[{{flag.flag_instrument_pt}}]</span> </span>
        </span>
        <span v-if="operation">
            <b>ID {{operation.opvid}}</b> {{op_type}} {{operation.q}} {{instrument}} {{instrument_detailed}}
        </span>
    </div>
</template>

<script>
export default {
    props: {
        flag: {
            required : false,
            type     : Object,
            default  : function () {return {}}
        },
        operation: {
            required: true
        },
        container_cur: {
            required: true
        }
    },

    computed: {
        op_type(){
            return this.operation.t.replace(/_/g, " ")
        },

        instrument(){
            if (['future', 'option'].includes(this.operation.asset))
                return this.operation.dscode
            else
                return this.operation.isin
        },

        instrument_detailed(){
            if (this.operation.asset === 'cash' && ['deposit','withdraw','collateral_block','collateral_unblock','transfer'].includes(this.operation.t)){
                if (this.operation.isin !== this.container_cur)
                    return '@ ' + this.operation.tcur_prate + ' ' + this.container_cur;
                else
                    return ''
            }
            else if (['collateral_block','collateral_unblock'].includes(this.operation.t)) {
                return ''
            }
            else {
                let html = ' @ ' + this.operation.tpr + ' ' + this.operation.tcur
                if (this.operation.tcur !== this.container_cur)
                    html += ' @ ' + this.operation.tcur_prate + ' ' + this.container_cur;
                return html
            }
        }
    }
}
</script>
