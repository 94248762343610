<template>
    <PageBlock 
        title="Fees"
        :loading="loading"
        can-export
        can-fullscreen>

        <template v-slot:buttons>
            <div class="prtf-page-item-data-btn"><el-select v-model="portfolio_mode" @change="get_list" size="mini">
                <el-option label="By value date" value="value"></el-option>
                <el-option label="By trade date" value="trade"></el-option>
            </el-select></div>
        </template>

        <vxe-table 
            :data="list" 
            size="mini" 
            class="common-grid" 
            v-loading="loading"
            auto-resize
            :footer-method="footer_method"
            show-footer
            footer-row-class-name="common-grid__footer"
            :sort-config=sort_config>
            
            <vxe-table-column 
                field="date" 
                title="Date" 
                width="120"
                formatter="formatDateTime">
            </vxe-table-column>
            <vxe-table-column 
                field="cur"
                title="Currency" 
                min-width="80">
            </vxe-table-column>
            <vxe-table-column 
                title="Amount" 
                min-width="150"
                align="right">
                <template #default="{ row }">
                    <FormattedNumber
                        :value="row.am"
                        :decimals="2"/>
                </template>
            </vxe-table-column>
            <vxe-table-column 
                title="Amount" 
                min-width="150"
                align="right">
                <template #default="{ row }">
                    <FormattedNumber
                        :value="row.prate"
                        :decimals="4"/>
                </template>
            </vxe-table-column>
            <vxe-table-column 
                title="Amount in Portfolio Cur." 
                min-width="150"
                align="right">
                <template #default="{ row }">
                    <FormattedNumber
                        :value="row.am * row.prate"
                        :decimals="2"/>
                </template>
                <template #footer="{ items, _columnIndex }">
                    <FormattedNumber
                        :value="items[_columnIndex]"
                        :decimals="2"/>
                </template>
            </vxe-table-column>
            <vxe-table-column 
                title="Operation" 
                min-width="300">
                <template #default="{ row }">
                    <TransactionText
                        :operation="row['operation']"
                        :container_cur="portfoliocur" />
                </template>
            </vxe-table-column>
        </vxe-table>

    </PageBlock>
</template>

<script>
import PageBlock from '@/components/PageBlock'
import FormattedNumber from '@/components/Formats/FormattedNumber'
import FormattedDate from '@/components/Formats/FormattedDate'
import TransactionText from '@/components/PageComponents/TransactionText'

export default {
    name: 'isin_accounts_movement',
    components: { PageBlock, FormattedNumber, FormattedDate, TransactionText },

    props: {
        clientid: {
            required: true,
        },
        portfolioid: {
            required: true,
        },
        portfoliocur: {
            required: true,
        },
        isin: {
            required: true,
        },
        pt: {
            required: true,
        },
        asset: {
            required: true,
        },
        period_from: {
            required: true,
        },
        period_to: {
            required: true,
        },
    },

    data(){
        return {
            list            : [],
            loading         : false,
            portfolio_mode  : 'trade',
            sort_config: {
                defaultSort: {
                    field: "date",
                    order: "desc"
                }
            }
        }
    },

    methods: {

        get_list(){
            this.loading = true
            let search_params = {
                isin          : this.isin,
                client_id     : this.clientid,
                portfolio_id  : this.portfolioid,
                date_from     : this.period_from,
                date_to       : this.period_to,
                mode          : this.portfolio_mode,
                position_type : this.pt,
            }

            this.$store.dispatch('instrument/getFees', search_params).then((response) => {
                this.list = response
                this.loading = false
            })
        },

        sum_am (list) {
            let count = 0
            list.forEach(item => {
                count += item.am * item.prate
            })
            return count
        },

        footer_method({ columns, data }){
            let footer_row = []
            columns.forEach((column, columnIndex) => {
                if (columnIndex === 0) {
                    footer_row.push('Total')
                }
                else if (columnIndex === 4) {
                    footer_row.push(this.sum_am(data))
                }
                else {
                    footer_row.push('')
                }
            })
            return [footer_row]
        },
    },

    mounted(){
        this.get_list();
    }
}
</script>
