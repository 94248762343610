var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageBlock',{attrs:{"title":"Fees","loading":_vm.loading,"can-export":"","can-fullscreen":""},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('div',{staticClass:"prtf-page-item-data-btn"},[_c('el-select',{attrs:{"size":"mini"},on:{"change":_vm.get_list},model:{value:(_vm.portfolio_mode),callback:function ($$v) {_vm.portfolio_mode=$$v},expression:"portfolio_mode"}},[_c('el-option',{attrs:{"label":"By value date","value":"value"}}),_c('el-option',{attrs:{"label":"By trade date","value":"trade"}})],1)],1)]},proxy:true}])},[_c('vxe-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"common-grid",attrs:{"data":_vm.list,"size":"mini","auto-resize":"","footer-method":_vm.footer_method,"show-footer":"","footer-row-class-name":"common-grid__footer","sort-config":_vm.sort_config}},[_c('vxe-table-column',{attrs:{"field":"date","title":"Date","width":"120","formatter":"formatDateTime"}}),_c('vxe-table-column',{attrs:{"field":"cur","title":"Currency","min-width":"80"}}),_c('vxe-table-column',{attrs:{"title":"Amount","min-width":"150","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.am,"decimals":2}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Amount","min-width":"150","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.prate,"decimals":4}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Amount in Portfolio Cur.","min-width":"150","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.am * row.prate,"decimals":2}})]}},{key:"footer",fn:function(ref){
var items = ref.items;
var _columnIndex = ref._columnIndex;
return [_c('FormattedNumber',{attrs:{"value":items[_columnIndex],"decimals":2}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Operation","min-width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('TransactionText',{attrs:{"operation":row['operation'],"container_cur":_vm.portfoliocur}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }